<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Declaração de Contribuição
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-4">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="cityId"
              >
                <v-autocomplete
                  v-model="form.cityId"
                  item-value="id"
                  item-text="name"
                  label="Prefeitura"
                  hide-details="auto"
                  :items="cities"
                  :error-messages="errors"
                  :loading="loadingCities"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
          <v-col>
            <v-select
              v-model="form.lastYearReceiptContributionRangeNumber"
              item-value="id"
              item-text="title"
              label="Faixa de contribuição do ano anterior"
              hide-details="auto"
              hint=
                "Faixa de contribuição a ser usada na geração da declaração referente ao ano anterior.
                 Selecione 'mesma do ano atual' para usar a mesma faixa do ano atual.
                 Deixe em 'automático' para usar a faixa de acordo com o índice."
              persistent-hint
              :items="contributionsRanges"
              :loading="loading || loadingCities"
            />
          </v-col>
        </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="purple"
          :loading="loading"
          outlined
          @click="download()"
        >
          <v-icon
            v-text="'mdi-download'"
            left
          />
          Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import date from '@/utils/date'
  import citiesApi from '@/api/cities'
  import receiptsApi from '@/api/receipts'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      city: {
        type: Object,
      },
    },

    data () {
      return {
        loading: false,
        loadingCities: false,
        cities: [],
        form: {
          cityId: null,
          lastYearReceiptContributionRangeNumber: 'current',
        },
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      contributionsRanges () {
        const ranges = [{ id: 'current', title: 'Mesma do ano atual' }, { id: 'auto', title: 'Automático' }]
        for (let id = 1; id <= 10; id++) {
          ranges.push({ id, title: id })
        }

        return ranges;
      }
    },

    watch: {
      show (val) {
        if (!val) return

        this.loadCities()
      },
    },

    methods: {

      async download () {
        try {
          this.loading = true

          const response = await receiptsApi.downloadContributionDeclaration(this.form)

          const linkSource = `data:application/pdf;base64,${response.data.pdf}`
          const downloadLink = document.createElement('a')
          const fileName = `declaracao_contribuicao_${this.form.cityId}`

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()

          this.show = false
        } catch (e) {
          if (e.response && e.response.status === 422) {
            return this.$refs.observer.setErrors(
              this.$apiError.fieldsValidation(e),
            )
          }

          const errorColor = e.response.status === 400 ? 'warning' : 'error'
          this.$snackbar.show({ color: errorColor, message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async loadCities () {
        try {
          this.loadingCities = true

          const response = await citiesApi.list({
            limit: 1000,
            offset: 0,
          })

          this.cities = response.data.cities
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingCities = false
        }
      },

    },

  }
</script>
